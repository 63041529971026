import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field } from "formik";

//import styles
import s from "./input.module.css";

//import components
import { InvalidError } from "..";
import { getZipSuggestions } from "../../../api/api";

const ZipCodeInput = ({
  label,
  placeholder,
  onChange,
  name,
  mainClassName,
  suggestions,
  setSuggestions,
  setIsBtnDisabled,
  dark,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [suggShow, setSuggShow] = useState(false);

  const inputRef = useRef();
  const input = useRef();
  const [blurred, setBlurred] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const debounceTime = 100; //in milisecods

  useEffect(() => {
    if (!blurred) {
      if (searchTerm === "") {
        setSuggestions([]);
        // setSuggShow(false);
      }
      const timerId = setTimeout(async () => {
        if (searchTerm !== "") {
          setIsBtnDisabled(true)
          const suggestionRes = await getZipSuggestions(searchTerm);
          if(suggestionRes) {
            setIsBtnDisabled(false)
          }
          setSuggestions((sug) => [...sug, ...suggestionRes]);
          // setSuggShow(true);
        }
      }, debounceTime);

      return () => clearTimeout(timerId);
    }
  }, [searchTerm, blurred]);

  const onBlur = () => {
    setActiveIndex(0);
    setBlurred(true);
    // setSuggShow(false);
  };

  function handleClickOutside(event) {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      onBlur();
    }
  }

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`form-group ${mainClassName} ${s.FormGridCol}`}>
      <label className={`label-primary ${dark && "dark"}`}>{label}*</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;

          const handleSearchChange = (event) => {
            const { value } = event.target;
            if (!isNaN(Number(value))) {
              setSearchTerm(value);
              setFieldValue(name, value);
            }
          };

          const keyDown = (key) => {
            const up = key.keyCode === 38;
            const down = key.keyCode === 40;
            if (suggestions.length) {
              if (up) {
                setActiveIndex((ind) => {
                  if (ind < 0 || ind === 0)
                    setActiveIndex(suggestions.length - 1);
                  else if (ind > 0) setActiveIndex(ind - 1);
                });
              } else if (down) {
                setActiveIndex((ind) => {
                  if (ind > suggestions.length - 2) setActiveIndex(0);
                  else if (ind > 0 || ind === 0) setActiveIndex(ind + 1);
                });
              } else if (key.keyCode === 13) {
                handleClick();
              }
            }
          };

          const handleClick = () => {
            const selected = suggestions[activeIndex];
            setSearchTerm(selected.zipcodes);
            setFieldValue(name, selected.zipcodes);
            input.current.blur();
            onBlur();
          };

          const onFocus = () => {
            setBlurred(false);
          };

          return (
            <div ref={inputRef}>
              <input
                className="form-control"
                placeholder={placeholder}
                value={value}
                onChange={handleSearchChange}
                onKeyDown={keyDown}
                onFocus={onFocus}
                ref={input}
              />
              {/* {suggShow && (
                <div className="autocomplete-dropdown-container">
                  <div className={suggestions.length && "border shadow p-1"}>
                    {suggestions.map((suggestion, index) => {
                      const active = index === activeIndex;
                      const className = `${
                        active ? "suggestion-item--active" : "suggestion-item"
                      }  py-2 px-1`;
                      const style = {
                        backgroundColor: active ? "#fafafa" : "#ffffff",
                        cursor: "pointer",
                      };
                      return (
                        <div
                          onMouseOver={() => setActiveIndex(index)}
                          className={className}
                          style={style}
                          onClick={handleClick}
                        >
                          <span>{suggestion?.zipcodes}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )} */}
            </div>
          );
        }}
      </Field>
      <ErrorMessage
        children={(err) => <InvalidError text={err} />}
        name={name}
      />
    </div>
  );
};

export default ZipCodeInput;
