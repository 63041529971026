import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import { Select } from "antd";

//import styles
import s from "./input.module.css";

//import components
import { InvalidError } from "..";

const SelectForAdditional = ({ label, dark, name, options, mainClassName }) => {
  const [open, setopen] = useState(false);

  return (
    <div className={mainClassName}>
      <div className={`form-group ${s.FormGridCol}`}>
        <label className={`label-primary ${dark && "dark"}`}>{label}</label>
        <Field name={name}>
          {({ form, field }) => {
            const { setFieldValue } = form;
            const { value } = field;

            const handleCheckboxChange = (checkedValue) => {
              setFieldValue(name, checkedValue.value);
              setopen(false);
            };

            const menu = (
              <div className="border shadow w-100 p-2 m-0">
                {options.map((v, i) => {
                  return (
                    <div
                      onClick={() => handleCheckboxChange(v)}
                      className={s.CustomOption}
                    >
                      {v.label}
                    </div>
                  );
                })}
              </div>
            );

            return (
              <Select
                onDropdownVisibleChange={(visible) => setopen(visible)}
                open={open}
                placeholder="Please select"
                value={value}
                onChange={handleCheckboxChange}
                dropdownRender={() => menu}
                options={options}
                className="custom-select-multiple w-100"
              />
            );
          }}
        </Field>

        <ErrorMessage
          children={(err) => <InvalidError text={err} />}
          name={name}
        />
      </div>
    </div>
  );
};

export default SelectForAdditional;
