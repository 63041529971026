import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//import components
import OrderSummary from "../../layouts/BillingInfo/order-summary";
import BillingInformation from "../../layouts/BillingInfo/billing-information";
import LogRocket from 'logrocket';

//import functions
import {
  breadCrumbChange,
  updateListenerAdded,
} from "../../../redux/reducers/appreducer";
import { billingInfoValidation } from "../../../actions/validations";
import {
  addToCartLocalStorage,
  getCartLocalStorage,
  getReCaptchaToken,
  getZipFromLocal,
} from "../../../actions/localstorage";
import { placeOrder } from "../../../actions/commonFunctions";
import { Spinner } from "../../common";
import {loadPayengine} from "payengine";

let payabliComponent;

const PaymentComponent = () => {
  const zipData = getZipFromLocal();
  const cartData = getCartLocalStorage();

  const {
    serviceAddress,
    billingInformation: {
      email,
      city,
      address,
      zipCode,
      suite,
      state,
      phoneNumber,
      lastName,
      firstName,
      town,
      street,
    },
  } = cartData || { billingInformation: {} };

  const [sameAsTheService, setSameAsTheSevice] = useState(true);
  const listenerAdded = useSelector((state) => state.listenerAdded);
  const [btnDisable, setBtnDisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cardErr, setCardErr] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isPayabliLoaded, setIsPayabliLoaded] = useState(true);

  const engineRef = useRef();
  const companyData = JSON.parse(localStorage.getItem('companyData'));
  const { entryPoint, paymentGatewayUsed } = companyData;

  const [data, setData] = useState({
    firstName,
    lastName,
    address: sameAsTheService ? serviceAddress?.address : address,
    suite: suite ? suite : "",
    city: sameAsTheService ? serviceAddress?.city : city,
    state: sameAsTheService ? serviceAddress?.state : state,
    zipCode: zipCode ? zipCode : zipData?.zipCode,
    phoneNumber: phoneNumber ? phoneNumber : "",
    email: email ? email : "",
    creditCard: "",
    cardExp: "",
    cardCVC: "",
    termsChecked: false,
    formatted_address: null,
    sameAsService: sameAsTheService,
    street: sameAsTheService ? serviceAddress?.street : street,
    town: sameAsTheService ? serviceAddress?.town : town,
  });

  const changeAddressCheck = (e) => {
    const { checked } = e.target;
    setSameAsTheSevice(checked);
    setData((data) => ({
      ...data,
      address: checked ? serviceAddress?.address : address ? address : "",
      city: checked ? serviceAddress?.city : city ? city : "",
      state: checked ? serviceAddress?.state : state ? state : "",
      zipCode: zipCode ? zipCode : zipData?.zipCode,
      street: checked ? serviceAddress?.street : street ? street : "",
      town: checked ? serviceAddress?.town : town ? town : "",
    }));
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(breadCrumbChange(3));
  }, []);

  useEffect(() => {
    setIsPayabliLoaded(false);
  },[]);

  useEffect(() => {
    if(paymentGatewayUsed === 'PAY_ENGINE')
    {
      const currentURL = window.location.href;
      let publicKey = "";
      if(currentURL.startsWith('https://dev-ecommerce.curbwaste.com') || currentURL.startsWith('https://qa-ecommerce.curbwaste.com') || currentURL.startsWith('https://alpha-ecommerce.curbwaste.com')) {
        publicKey = 'pk_test_Hw7bV443o0gMMtbTtKHMedhRqLCGDWJG';
      } else if(currentURL.startsWith('https://ecommerce.curbwaste.com')) {
        publicKey = 'pk_prod_A15WZbtr9omfv20pZjCwautjPX9kdq5y';
      } else {
        publicKey = process.env.REACT_APP_PAYENGINE_PUBLIC_KEY;
      }
      // Load Payengine on first render only
      loadPayengine({
        publicKey,
      }).then((pe) => {
        setBtnDisable(false);
      });
    }
  }, []);

  useEffect(() => {
    if(paymentGatewayUsed === 'PAYABLI' && entryPoint !== '' &&  !isPayabliLoaded)
    {
      const currentURL = window.location.href;
      let publicKey = "";
      if(currentURL.startsWith('https://dev-ecommerce.curbwaste.com') || currentURL.startsWith('https://qa-ecommerce.curbwaste.com') || currentURL.startsWith('https://alpha-ecommerce.curbwaste.com')) {
        publicKey = 'o.Z4WuIRWA1lbBzfucMkb1L9c5hvM/B30vbR/aqKKRJxTDkYlHvQ2QFF2DayfgQkbGmF1/OkfLSfwcCFKiUMNX6sT48Zo+RQVEe6AcyrzU/yDwjN476rbmwNUnTzh8kZW5bP/VFMJ/izqR9ykwKa/ge5GsiIckLD73kUO8d0fu+DifpJKtEA4khRfe89E1ewwAqoP7g6JhT0QYq9UqU2yV+3goZcOkqzoPZttMn3dE89Sw076h6ykyynPTskPTD2mEV6kkj0fXMSLAoCcRgJGwpJkOYYaEEPeQ04P7sLaReIdvQpYeMDO1rp2vOPIg/T30p9QM/9ksrGllu8GkBBK/tLA2G0Zh/HduE7LZONXa23QnRoLakT3jerR6NtwRT4Opwresxz87FMjcptc3+5JJ0X7n/i8+lJR9QBmkMwzfqi4OkhushguZ8eDcRi5ozX7qeg8sOZJ/nUDyfQfXcw3hq7rkmew9DaU/hiRicPrIWehDRXw/EqCK4f2L0aJiSK/d.2LSfOgS0SPtdE2gQjbUsBH4vXhh7n2YRRDnitWSZDM8=';
      } else if(currentURL.startsWith('https://ecommerce.curbwaste.com')) {
        publicKey = 'o.ubMVdIGEUHXH2CCv78dSL7fr+HkAI3JOHgjLi9fwW7czkH49KiTrvdZwhyWSwpoWBYV4mv0nw6xUXWZgPraVoBfqxvmCE7ZV00z/DqJ1R0r9QoZwVuKpDmc5Qaz1jeHICjA7F5MNU7HfmGGJFEsAaegn6tL8DS2eoHgJQomCRRRFHsWAU00/wiJnT3x3t4FW58HAc/B2niS8FZQmFR5c/EyNPto5VBYYfVuV2bhR2bo7Km6wf9vE/UNm3Fcu23o9YVSlEEniBbLRI3gZd1Rqx6Hc+MYLRrz+9L+IIVSvP58+87De/MB2ENXXkC/ypsNQBHqS8+Alep2Npg9DErF15Nn/FIS3dFTNBXOiWK5/CII63VkKoRetEqeqO8dA8IE0RSqxpuk9TrR8BVFXToFLGkp5jK+HHVDVKUYSXUK6f1EiHXrIXCPAQ58aRuplp4Gat3XAzmZPOU5CsaoAfC1nys7wTdri8XAvFGU4Ajr3rDBeEcihXsvsXAtdd5Cv0nul.T1gO53UBrqh0W2nBWN3M4vRt3MQfYk3jSSfhcuC6EL4=';
      } else {
        publicKey = process.env.REACT_APP_PAYABLI_PUBLIC_KEY;
      }
      console.log('payabli loading...');
      const payabliConfig0 = {
        type: 'methodEmbedded',
        rootContainer: 'pay-component',
        entryPoint,
        defaultOpen: 'card',
        token: publicKey,
        temporaryToken:false,
        createAnonymous: true,
        card: {
          enabled: true,
          amex: true,
          discover: true,
          visa: true,
          mastercard: true,
          inputs: {
            cardHolderName: {
              label: 'Card holder',
              placeholder: '',
              floating: true,
              size: 12,
              row: 0,
              order: 0
            },
            cardNumber: {
              label: 'Card Number',
              placeholder: '1234 1234 1234 1234',
              floating: true,
              size: 12,
              row: 2,
              order: 0
            },
            cardExpirationDate: {
              label: 'Expiration Date',
              placeholder: 'MM/YY',
              floating: true,
              size: 6,
              row: 3,
              order: 1
            },
            cardCvv: {
              label: 'CVV/CVC',
              placeholder: 'CVV/CVC',
              floating: true,
              size: 6,
              row: 3,
              order: 2
            }
          }
        },
        ach:{
            enabled: false,
            checking: true,
            savings: false
        },
        customerData: {
          firstName: data.firstName,
          lastName: data.lastName,
          billingEmail: data.email,
        },
        functionCallBackSuccess: async function (response) {
          console.log("response", response.responseData);
          const orderResponse = await placeOrder(response.responseData, navigate);
          if (orderResponse === "Succees") {
            setLoading(false);
            navigate("/thanks");
          } else {
            setLoading(false);
          }
        },
        functionCallBackReady: function (data) {
          setBtnDisable(false);
        },
        functionCallBackError: function (errors) {
          console.log('Payabli Error!',errors);
        },
      };

      try {
        // eslint-disable-next-line no-undef
        payabliComponent = new PayabliComponent(payabliConfig0);
        payabliComponent.showModal();
        setLoading(false);
        setIsPayabliLoaded(true);
      } catch (payabliLoadingException) {
        console.log('Payabli : Loading error', payabliLoadingException);
      }
    }
  }, [isPayabliLoaded,entryPoint]);

  const handleSubmit = async (values) => {
    setLoading(true);
    if(companyData?.paymentGatewayUsed === "PAYABLI") {
      payabliComponent.payabliConfig.customerData = {
        firstName: values.firstName,
        lastName: values.lastName,
        billingEmail: values.email,
      }
      const { address, city, state, zipCode } = data;
      const dataForSend = {
        ...cartData,
        billingInformation: { ...values, address, city, state, zipCode },
      };
      addToCartLocalStorage(dataForSend);
      try {
        await payabliComponent.payabliExec('method');
      } catch (e) {
        if(e?.response?.data?.error?.message) {
          alert(e.response.data.error.message);
        } else {
          alert("Something wrong");
        }
        setLoading(false);
      }
    } else if(companyData?.paymentGatewayUsed === "PAY_ENGINE") {
      const component = engineRef.current;
      !listenerAdded &&
        component.addEventListener("success", async function (card) {
          setCardErr(null);
          const orderResponse = await placeOrder(card.detail, navigate);
          if (orderResponse === "Succees") {
            setLoading(false);
            navigate("/thanks");
          } else {
            setLoading(false);
          }
        });

      //--> Error handler
      !listenerAdded &&
        component.addEventListener("error", function (e) {
          // console.log("Unable to create card", e.detail);
          setLoading(false);
          setCardErr("Card Information not valid");
        });
      dispatch(updateListenerAdded(true));
      const { address, city, state, zipCode } = data;
      const dataForSend = {
        ...cartData,
        billingInformation: { ...values, address, city, state, zipCode },
      };
      addToCartLocalStorage(dataForSend);
    LogRocket.identify(email, {
      name: firstName + lastName,
      email
    });
    LogRocket.log('Order submitted', dataForSend);
      component.submit();
      setLoading(true);
    }
  };

  const navigateToProduct = () => {
    navigate(`/product?zipcode=${zipData.zipCode}&date=${zipData.date}`);
  };

  const reCaptchaToken = getReCaptchaToken();

  return (
    <>
      <Formik
        initialValues={{ ...data }}
        validate={(values) => billingInfoValidation(values, sameAsTheService,data)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="container">
              <div className="form-header mt-3">
                <div className="form-header-col">
                  <div className="form-header-col-inner">
                    <h1 className="form-header-title">Payment </h1>
                    <p className="form-header-subtitle">
                      Enter some final details to complete your order
                    </p>
                  </div>
                </div>
                <div className="form-header-col"></div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <OrderSummary
                    cartData={cartData}
                    navigateToProduct={navigateToProduct}
                  />
                </div>
                <div className="col-md-8">
                  <BillingInformation
                    termsChecked={termsChecked}
                    setTermsChecked={setTermsChecked}
                    cardErr={cardErr}
                    setBtnDisable={setBtnDisable}
                    engineRef={engineRef}
                    changeAddressCheck={changeAddressCheck}
                    data={data}
                    setData={setData}
                    setSameAsTheSevice={setSameAsTheSevice}
                    sameAsTheService={sameAsTheService}
                  />
                  <div className="form-footer">
                    <button
                      disabled={btnDisable || loading || !termsChecked || !reCaptchaToken}
                      type="submit"
                      className="btn btn-primary"
                    >
                      {loading ? (
                        <Spinner submitSpinner={'submitSpinner'}/>
                      ) : (
                        "Complete Order"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default PaymentComponent;
