import axios from "axios";

const currentURL = window.location.href;

let API_URL = '';

if(currentURL.startsWith('https://dev-ecommerce.curbwaste.com')) {
  API_URL = 'https://dev-postgres-api.curbwaste.com/api/';
} else if(currentURL.startsWith('https://qa-ecommerce.curbwaste.com')) {
  API_URL = 'https://qa-api.curbwaste.com/api/';
} else if(currentURL.startsWith('https://alpha-ecommerce.curbwaste.com')) {
  API_URL = 'https://alpha-postgres-api.curbwaste.com/api/';
} else if(currentURL.startsWith('https://ecommerce.curbwaste.com')) {
  API_URL = 'https://prod-v2-api.curbwaste.com/api/';
} else if(currentURL.startsWith('https://mycurbside.com')) {
  API_URL = 'https://alpha-api.curbwaste.com/api/';
} else {
  API_URL = process.env.REACT_APP_API_URL;
}

const PAYENGINE_URL = process.env.REACT_APP_PAYENGINE_API_URL;

export const SERVER_AXIOS = axios.create({
  baseURL: API_URL,
});

export const PAYENGINE_AXIOS = axios.create({
  baseURL: PAYENGINE_URL,
});
