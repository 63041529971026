// import { isValidPhoneNumber } from "react-phone-number-input";
import { validateEmail } from "./commonFunctions";

export const zipCodeValidation = (values, suggestions) => {
  const errors = {};

  const included = suggestions.filter((sug) => sug.zipcodes === values.zipCode);

  if (!values.zipCode) {
    errors.zipCode = "Zipcode Required";
  }
  if (included.length < 1) {
    errors.zipCode = "We're sorry, we do not provide service in this zip code";
  }
  if (!values.date) {
    errors.date = "Date Required";
  }

  return errors;
};

export const serviceDetailsValidation = (values, suggestions) => {
  const errors = {};

  const included = suggestions.filter((sug) => sug.zipcodes === values.zipCode);

  if (!values.address) {
    errors.address = "Address Required";
  }
  if (!values.city) {
    errors.city = "City Required";
  }
  if (!values.state) {
    errors.state = "State Required";
  }
  if (!values.zipCode) {
    errors.zipCode = "Zip Code Required";
  }
  if (included.length < 1) {
    errors.zipCode = "We're sorry, we do not provide service in this zip code";
  }
  if (!values.deliveryDate) {
    errors.deliveryDate = "Delivery Date Required";
  }

  return errors;
};

export const billingInfoValidation = (values, sameAsService,data) => {
  const errors = {};
  
  if (!values.firstName) {
    errors.firstName = "First name Required";
  }
  if (!values.lastName) {
    errors.lastName = "Last name Required";
  }
  if (!sameAsService && !data.address) {
    errors.address = "Address Required";
  }
  if (!sameAsService && !data.city) {
    errors.city = "City Required";
  }
  if (!sameAsService && !data.state) {
    errors.state = "State Required";
  }
  if (!sameAsService && !data.zipCode) {
    errors.zipCode = "Zip code required";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone number required";
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = "Phone number is not valid";
  }
  if (!values.email) {
    errors.email = "Please enter email";
  } else if (!validateEmail(values.email)) {
    errors.email = "Please enter valid email";
  }
  // if (!values.cardExp) {
  //   errors.cardExp = "Expiry date Required";
  // }
  // if (!values.cardCVC) {
  //   errors.cardCVC = "CVC Required";
  // }

  return errors;
};
