import { ErrorMessage, Field } from "formik";
import React from "react";
import { DatePicker, ConfigProvider } from "antd";
import dayjs from "dayjs";

//import components
import { InvalidError } from "..";
import { CalendarIcon } from "../../svgicons";

//import styles
import s from "./input.module.css";

//import functions
import { disabledDate } from "../../../actions/commonFunctions";

const DateInput = ({ label, name, deliveryDate, dark, required }) => {
  return (
    <div className={`form-group ${s.FormGridCol}`}>
      <label className={`label-primary ${dark && "dark"} `}>{label}{required ? '*' : null}</label>
      <ConfigProvider
      theme={{
          token: {
              colorPrimaryHover:"var(--primary-color)",
          }
      }}>
        <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DatePicker
              value={value && value}
              format="MM-DD-YYYY"
              disabledDate={(e) =>
                disabledDate(e, deliveryDate && form.values.deliveryDate)
              }
              className="form-control"
              onChange={(val) => {
                setFieldValue(name, val !== null ? dayjs(val) : val);
              }}
              suffixIcon={<CalendarIcon />}
            />
          );
        }}
      </Field>
      </ConfigProvider>

      <ErrorMessage
        children={(err) => <InvalidError text={err} />}
        name={name}
      />
    </div>
  );
};

export default DateInput;
