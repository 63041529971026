import React from "react";
import s from "./Spinner.module.css";

const Index = ({appSpinner, submitSpinner}) => {

  return (
      <div className={appSpinner ? s.appSpinnerContainer : submitSpinner ? s.submitSpinnerContainer : s.submitSpinnerContainer}>
          <div className={appSpinner ? s.appLoadingSpinner : submitSpinner ? s.submitLoadingSpinner : s.submitLoadingSpinner}></div>
      </div>
  );
};

export default Index;
