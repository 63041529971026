import React from "react";
import s from "./input.module.css";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { addReCaptchaToken } from "../../../actions/localstorage";

const GoogleReCaptchaComponent = ({ mainClassName }) => {
    const currentURL = window.location.href;

    let SITE_KEY = '';

    if (currentURL.startsWith('https://dev-ecommerce.curbwaste.com')) {
        SITE_KEY = "6LfLfGAoAAAAAMwMNwNRNPu45Ye_DjV7H5TySCiO"
    } else if (currentURL.startsWith('https://qa-ecommerce.curbwaste.com')) {
        SITE_KEY = "6LfNfmAoAAAAAFkPX_mtO-gjZH3P9PH4gApSZHk_"
    } else if (currentURL.startsWith('https://alpha-ecommerce.curbwaste.com')) {
        SITE_KEY = "6Lc3f2AoAAAAAJuIx0uBDw8vqaIEbJKIQ-pV4_Dt"
    } else if (currentURL.startsWith('https://mycurbside.com')) {
        SITE_KEY = "6Lc3f2AoAAAAAJuIx0uBDw8vqaIEbJKIQ-pV4_Dt"
    } else if (currentURL.startsWith('https://ecommerce.curbwaste.com')) {
        SITE_KEY = "6LcFgGAoAAAAAB0dCFqslqMX_JL-9E5j8l4KDs8y"
    } else {
        SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
    }

    const onCaptchaChange = async (token) => {
        await addReCaptchaToken(token);
    }

    return (
        <div className={mainClassName}>
            <div className={`form-group ${s.FormGridCol}`}>
                <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
                    <GoogleReCaptcha onVerify={onCaptchaChange} />
                </GoogleReCaptchaProvider>
            </div>
        </div>
    );
};

export default GoogleReCaptchaComponent;
