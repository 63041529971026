import React from "react";
import s from "./servicedetails.module.css";
import Thumb from "./../../../assets/images/product-thumb.svg";
import { addToCartLocalStorage } from "../../../actions/localstorage";
import { useDispatch, useSelector } from "react-redux";
import { addProductToCart } from "../../../redux/reducers/appreducer";

const ProductSummary = ({ allSkUS }) => {
  const data = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const companyData = JSON.parse(sessionStorage.getItem('companyData'));

  const onChange = (e) => {
    const { value } = e.target;
    const selected = allSkUS.filter((sku) => sku.product.id === value);
    const sum = data.additionalFee.reduce((ent, currentValue) => {
      return (
        ent +
        Number(currentValue?.charge?.value) *
          (currentValue.quantity ? Number(currentValue.quantity) : 0)
      );
    }, 0);
    const cartCreate = {
      ...data,
      ...selected[0],
      ammount: selected[0]?.pricingStructure?.price + sum,
    };
    addToCartLocalStorage(cartCreate);
    dispatch(addProductToCart(cartCreate));
  };

  return (
    <div className="col-md-4">
      <div className="form-title">Product Summary</div>
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <label className="label-primary">Select Different Product</label>
            <select onChange={onChange} className="custom-select form-control">
              {allSkUS?.map((v, i) => (
                <option
                  selected={v.product.id === data?.product?.id}
                  value={v.product.id}
                >
                  {v.product.name}
                </option>
              ))}
            </select>
            {/* <div className="invalid-feedback">Error</div> */}
          </div>

          <div className={s.ProductThumb}>
            <img src={companyData?.theme?.productImages?.[data?.product?.id] ? companyData?.theme?.productImages[data?.product?.id] : Thumb} alt="" />
          </div>
          <div className={s.ProductTitle}>{data?.product?.name}</div>
          <ul className={s.ProductOverviewList}>
            {data?.feeStructure?.weight?.max && (
              <li>Weight Allowance: {data?.feeStructure?.weight?.max} Tons</li>
            )}
            {/* <li>Length: 23’</li>
            <li>Width: 8’</li>
            <li>Height: 6’</li> */}
          </ul>
        </div>
        <div className={s.ProductNote}>
          Not sure you’re getting the right product? <br />{" "}
          {/*<span>Give us a call and speak with one of our experts today!</span>*/}
          {
            companyData?.theme?.supportContactHTML ?
                <div className={s.contactDetail}>
                  <div dangerouslySetInnerHTML={{__html: companyData.theme.supportContactHTML}}/>
                </div> : null
          }
        </div>
      </div>
    </div>
  );
};

export default ProductSummary;
