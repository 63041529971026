import React, { useEffect } from "react";
import { loadPayengine, payengineStyles } from "payengine";
import s from "./Payment.module.css";
import { getMerchantId } from "../../../actions/commonFunctions";

function MyComponent({ engineRef, setBtnDisable, cardErr }) {
  useEffect(() => {
    const currentURL = window.location.href;
    let publicKey = "";
    if(currentURL.startsWith('https://dev-ecommerce.curbwaste.com')) {
      publicKey = 'pk_test_Hw7bV443o0gMMtbTtKHMedhRqLCGDWJG';
    } else if(currentURL.startsWith('https://qa-ecommerce.curbwaste.com')) {
      publicKey = 'pk_test_Hw7bV443o0gMMtbTtKHMedhRqLCGDWJG';
    } else if(currentURL.startsWith('https://alpha-ecommerce.curbwaste.com')) {
      publicKey = 'pk_test_Hw7bV443o0gMMtbTtKHMedhRqLCGDWJG';
    } else if(currentURL.startsWith('https://ecommerce.curbwaste.com')) {
      publicKey = 'pk_prod_A15WZbtr9omfv20pZjCwautjPX9kdq5y';
    } else if(currentURL.startsWith('https://mycurbside.com')) {
      publicKey = 'pk_test_Hw7bV443o0gMMtbTtKHMedhRqLCGDWJG';
    } else {
      publicKey = process.env.REACT_APP_PAYENGINE_PUBLIC_KEY;
    }
    // Load Payengine on first render only
    loadPayengine({
      publicKey,
    }).then((pe) => {
      setBtnDisable(false);
    });
  }, []);

  // const addEvents = useCallback((node) => {
  //   console.log("DSF", node);
  //   if (node !== null) {
  //     node.addEventListener("stepChange", function (e) {
  //       console.log("step changed", e.detail);
  //     });
  //   }
  // });

  return (
    <>
      <pay-engine
        id="creditcardform"
        ref={engineRef}
        merchant-id={getMerchantId()}
        type="creditcardform"
        styleModule={payengineStyles(s)}
      ></pay-engine>
      {cardErr && <div className="invalid-feedback">{cardErr}</div>}
    </>
  );
}
export default MyComponent;
