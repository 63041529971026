import React from "react";
import s from "./link.module.css";
import { Link } from "react-router-dom";

const TermsLink = () => {
  return (
    <Link
      to={"/terms-and-conditions"}
      target="_blank"
      className={`${s.CustomLink} mx-1 underline`}
    >
      terms & conditions.
    </Link>
  );
};

export default TermsLink;
