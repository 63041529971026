import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: ["Data From Redux"],
  cart: null,
  currentproduct: {},
  breadCrumbInd: -1,
  zipCodeData: {},
  taxrate: {},
  listenerAdded: false,
};

const counterSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    addProductToCart: (state, { payload }) => {
      state.cart = payload;
    },
    currentProductChange: (state, { payload }) => {
      state.currentproduct = payload;
    },
    breadCrumbChange: (state, { payload }) => {
      state.breadCrumbInd = payload;
    },
    addProduct: (state, { payload }) => {
      console.log("Add");
    },
    addZipData: (state, { payload }) => {
      state.zipCodeData = payload;
    },
    updateTaxRate: (state, { payload }) => {
      state.taxrate = payload;
    },
    updateListenerAdded: (state, { payload }) => {
      state.listenerAdded = payload;
    },
  },
});

export const {
  addProductToCart,
  currentProductChange,
  breadCrumbChange,
  addProduct,
  addZipData,
  updateTaxRate,
  updateListenerAdded
} = counterSlice.actions;
export default counterSlice.reducer;
