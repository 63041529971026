import React, { useEffect, useRef } from "react";

const AdditionalCard = ({ v, onChange, index }) => {
  const { quantity } = v;

  const inputRef = useRef();

  useEffect(() => {
    const input = inputRef.current;

    function preventScroll(event) {
      if (event.target === input) {
        event.preventDefault();
      }
    }

    input.addEventListener("wheel", preventScroll, { passive: false });

    return () => {
      input.removeEventListener("wheel", preventScroll);
    };
  }, []);

  return (
    <tr>
      <td className="table-cell-first">{v.name}</td>
      <td className="table-cell-last">
          {
              v?.charge?.type === 'PERCENTAGE' ?
                  <>
                      {v?.charge?.value ? v?.charge?.value : 0}
                      {v?.feePer && `/${v.feePer}`}%
                  </> :
                  <>
                      ${v?.charge?.value ? v?.charge?.value : 0}
                      {v?.feePer && `/${v.feePer}`}
                  </>
          }
      </td>
      <td className="table-cell-last">
        <input
          ref={inputRef}
          value={quantity !== 0 && quantity}
          name={v.name}
          onChange={(e) => onChange(e, index)}
          type="number"
          className="form-control table-form-control"
        />
      </td>
    </tr>
  );
};

export default AdditionalCard;
