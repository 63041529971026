import React, {useEffect} from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import {
  AdditionaleItems,
  BillingInfo,
  Home,
  Product,
  ServiceDetails,
  TermsAndCondition,
  Thanks,
} from "../components/pages";
import { BreadCrumb, Header } from "../components/common";
import { useDispatch } from "react-redux";
import { addProductToCart, updateTaxRate } from "../redux/reducers/appreducer";
import {
  getCartLocalStorage,
  getTaxRate,
} from "../actions/localstorage";
import ZipCodeComponent from "../components/pages/ZipCode";

function ScrollToTop({ children }) {
  const dispatch = useDispatch();

  const location = useLocation();
  let curLoc = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const cartData = getCartLocalStorage();
    const taxRate = getTaxRate();
    cartData && dispatch(addProductToCart(cartData));
    taxRate && dispatch(updateTaxRate(taxRate));
  }, [location]);

  const companyData = JSON.parse(sessionStorage.getItem('companyData'));

  const titleMap = [
    { path: "/", title: "Home" },
    { path: "/terms-and-conditions", title: "Terms & Conditions" },
    { path: "/thanks", title: "Thanks" },
    { path: "/payment", title: "Billing Info" },
    { path: "/service-details", title: "Service Details" },
    { path: "/additional-items", title: "Additional Items" },
    { path: "/product", title: "Select Your Product" },
  ];

  useEffect(() => {
    const curTitle = titleMap.find((item) => item.path === curLoc.pathname);
    if (curTitle && curTitle.title) {
        document.title = companyData?.companyName ? curTitle.title + ` | ${companyData.companyName}` : curTitle.title;
    }
  }, [companyData])

  return children;
}

const isComponentUrl = window.location.href.includes('components/zip-code');

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        {
          !isComponentUrl ?
              <>
                <Header otherClasses="MainHeader" />
                <BreadCrumb />
              </> : null
        }
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/additional-items" element={<AdditionaleItems />} />
          <Route path="/service-details" element={<ServiceDetails />} />
          <Route path="/payment" element={<BillingInfo />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
          <Route path="/components/zip-code" element={<ZipCodeComponent />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
