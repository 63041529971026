import { SERVER_AXIOS, PAYENGINE_AXIOS } from "./config";

//get request
export const GET_METHOD = async (apiUrl, header) =>
  await SERVER_AXIOS.get(apiUrl, header);

//get request
export const POST_METHOD = async (apiUrl, data, headers) =>
  await SERVER_AXIOS.post(apiUrl, data, headers);

//get request
export const PUT_METHOD = async (apiUrl, data) =>
  await SERVER_AXIOS.put(apiUrl, data);

//get request
export const DELETE_METHOD = async (apiUrl) =>
  await SERVER_AXIOS.delete(apiUrl);

// payengine post

export const PAY_POST = async (apiUrl, data, headers) =>
  await PAYENGINE_AXIOS.post(apiUrl, data, headers);
