import React from "react";
//import styles
import s from "./input.module.css";

//import components
import { TermsLink } from "..";

const TermsCheck = ({
  label,
  dark,
  name,
  mainClassName,
  setTermsChecked,
  termsChecked,
}) => {
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setTermsChecked(checked);
  };

  return (
    <div className={mainClassName}>
      <div className={`form-group ${s.FormGridCol}`}>
        <label className={`label-primary ${dark && "dark"}`}>{label}</label>
        <label className="custom__checkbox">
          I have read and understand the attached
          <input
            checked={termsChecked}
            name={name}
            className="custom__checkbox--input"
            type="checkbox"
            onChange={handleCheckboxChange}
          />
          <span className="custom__checkbox--check"></span>
        </label>
        <TermsLink />
      </div>
    </div>
  );
};

export default TermsCheck;
