import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Popover } from "antd";

//import styles
import s from "./breadcrumb.module.css";

//import components
import { CaretDownIcon } from "../../svgicons";
import { NavContent } from "./NavContent";
import { breadCrumb } from "../../../data/data";
import { currencyFormat } from "../../../actions/formatter";
import {
  getZipFromLocal,
} from "../../../actions/localstorage";
import Header from "../Header";
import CheckCircle from "../../../assets/images/circle-check.svg";
import { getTaxAmmount } from "../../../actions/commonFunctions";

const Index = () => {
  const breadIndex = useSelector((state) => state.breadCrumbInd);
  const taxrate = useSelector((state) => state.taxrate);
  const cartData = useSelector((state) => state.cart);

  const [isOpened, setIsOpened] = useState(false);

  const taxAmmount =
    cartData && taxrate?.combined_rate
      ? getTaxAmmount(taxrate?.combined_rate, cartData.ammount)
      : 0;

  const location = useLocation();
  const navigate = useNavigate();

  const breadClick = (v) => {
    if(v.url === "/product"){
      const zipData = getZipFromLocal()
      navigate(`${v.url}?zipcode=${zipData?.zipCode}&date=${zipData?.date}`)
    }else{
      navigate(v.url);
    }
  };

  const changePopOver = (s) => {
    setIsOpened(s);
  };

  if (
    location.pathname !== "/" &&
    location.pathname !== "/thanks" &&
    location.pathname !== "/terms-and-conditions"
  )
    return (
      <div className={s.Nav}>
        <Header otherClasses={`${s.MobileContentHeader}`} />
        {breadCrumb.map((v, i) => {
          return (
            <button
              key={i}
              onClick={() => breadClick(v)}
              className={`BreadcrumbPill btn ${s.NavLink} ${
                i < breadIndex
                  ? s.Fill
                  : i === breadIndex
                  ? s.Active
                  : "disabled"
              }`}
            >
              {v.name}
            </button>
          );
        })}

        <Popover
          className={`navcart-popover ${s.CartPopup}`}
          overlayClassName="navcart-popover-overlay"
          content={NavContent}
          trigger="click"
          placement="topRight"
          onOpenChange={changePopOver}
        >
          <div className={s.NavCart}>
            <div className={s.NavCartLabel}>Total</div>
            <div className={s.NavCartPrice}>
              <div className={s.NavCartTotalPrice}>
                {currencyFormat(cartData ? cartData?.ammount + taxAmmount : 0)}*
                <span className={s.NavCartPriceFees}>
                  *Additional fees may apply
                </span>
              </div>
              <CaretDownIcon
                style={{ transform: isOpened && "scaleY(-1)" }}
                className={s.Icon}
              />
            </div>
          </div>
        </Popover>

        {/* mobile breadcrumb */}

        <div className={`position-relative w-100 ${s.MobileContent}`}>
          <div className="w-100 d-flex justify-content-around py-3 px-3">
            {breadCrumb.map((v, i) => {
              return (
                <button
                  key={i}
                  onClick={() => breadClick(v)}
                  className={`btn ${s.NavLinkMob} ${
                    i < breadIndex
                      ? s.Fill
                      : i === breadIndex
                      ? s.Active
                      : s.Disabled
                  }  ${s.NavLinkMob} ${
                    i !== breadCrumb.length - 1 && s.ForAfter
                  }`}
                >
                  {i < breadIndex ? <img alt="CheckCircle" src={CheckCircle} /> : i + 1}
                  <div className={s.BreadCrumbTextMob}>
                    {i === breadIndex && v.name}
                  </div>
                </button>
              );
            })}
          </div>
          <div className={s.LineContainer}>
            {breadCrumb.slice(1, breadCrumb.length).map((v, i) => {
              return (
                <div
                key={i}
                  className={`${s.NavLinkMobLine} ${
                    i < breadIndex
                      ? s.Fill
                      : i === breadIndex
                      ? s.Active
                      : s.Disabled
                  } `}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
    );
};

export default Index;
