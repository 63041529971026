import React from "react";
import { ErrorMessage, Field } from "formik";
import { Tooltip } from "antd";

//import styles
import s from "./input.module.css";

//import components
import { InvalidError } from "..";
import { primaryColor } from "../../../data/constants";

const InputWithErr = ({
  label,
  placeholder,
  type,
  name,
  textarea,
  mainClassName,
  dark,
  tooltipText,
  tooltipTitle,
  maxlength,
  data,
  setData,
}) => {
  return (
    <div className={`form-group ${mainClassName} ${s.FormGridCol}`}>
      <label className={`label-primary ${dark && "dark"}`}>{label}{label === 'Special Instructions' ? null : '*'}</label>
      {tooltipText && (
        <Tooltip trigger={"click"} color={primaryColor} title={tooltipTitle}>
          <span className={s.CvcNumberInfo}>{tooltipText}</span>
        </Tooltip>
      )}
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          const onChange = (e) => {
            const { value } = e.target;
            if (!maxlength) {
              setFieldValue(name, value);
              setData && setData((data) => ({ ...data, [name]: value }));
            } else {
              value.length <= maxlength && setFieldValue(name, value);
              value.length <= maxlength &&
                setData &&
                setData((data) => ({ ...data, [name]: value }));
            }
          };
          if (!textarea) {
            return (
              <input
                type={type ? type : "text"}
                className="form-control"
                placeholder={placeholder}
                onChange={onChange}
                value={!data ? value : data[name] && data[name]}
              />
            );
          } else {
            return (
              <textarea
                type={type ? type : "text"}
                className="form-control"
                placeholder={placeholder}
                onChange={onChange}
                value={!data ? value : data[name] && data[name]}
              />
            );
          }
        }}
      </Field>
      <ErrorMessage
        children={(err) => <InvalidError text={err} />}
        name={name}
      />
    </div>
  );
};

export default InputWithErr;
