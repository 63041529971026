import React, {useEffect, useState} from "react";
import s from "./login.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  addZipData,
  breadCrumbChange,
} from "../../../redux/reducers/appreducer";
import { addZipToLocal } from "../../../actions/localstorage";
import { clearLocalStorage } from "../../../actions/commonFunctions";
import ZipCodeCommonComponent from "../ZipCodeComponent";
// import Background from '../../../assets/images/home-bg.jpg';
import ReactGA from "react-ga4";

const LoginComponent = () => {
  const navigate = useNavigate();
  const diapatch = useDispatch();
  const companyData = JSON.parse(sessionStorage.getItem('companyData'));
  const [suggestions, setSuggestions] = useState([]);

  const [data] = useState({
    zipCode: "",
    date: "",
  });
  const handleSubmit = (values) => {
    addZipToLocal(values);
    navigate(`/product?zipcode=${values.zipCode}&date=${values.date}`);
    diapatch(breadCrumbChange(-1));
    diapatch(addZipData(values));
    ReactGA.event({
      category: 'User Information',
      action: 'Form Submission',
      label: 'ZIP Code',
      value: values?.zipCode,
    });
  };

  useEffect(() => {
    clearLocalStorage();
  }, []);

  // const backgroundImage = companyData && companyData?.theme && companyData?.theme?.background ? companyData.theme.background : Background

  return (
    <>
      {
        companyData ?
            <div className={s.SectionDefault + ' backgroundImage'} >
              <div className={`row justify-content-between ${s.Container}`}>
                <div className={`col-sm-6 ${s.HeadingWrapper}`}>
                  {
                    companyData && companyData?.theme && companyData?.theme?.homePageHTML ?
                        <div className={s.HomeHeadingTitle}>
                          <div dangerouslySetInnerHTML={{__html: companyData.theme.homePageHTML}}/>
                        </div> : null
                  }
                  {/* <div className={s.HomeHeadingTitle}>
              Local, Affordable, Dependable
            </div>
            <div className={s.HomeDescription}>
              Dumpster Rentals of Northwest Arkansas & Fort Smith
            </div> */}
                </div>
                <div className={`rounded border col-sm-5 ${s.CardWrapper}`}>
                  <div className={s.HeadWrapper}>
                    <div className={s.HeadWrapperTitle}>Dumpster Rental</div>
                  </div>
                  <ZipCodeCommonComponent
                      data={data}
                      suggestions={suggestions}
                      setSuggestions={setSuggestions}
                      handleSubmit={handleSubmit}
                  />
                </div>
              </div>
            </div> : null
      }

    </>
  );
};
export default LoginComponent;
