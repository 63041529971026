import React, { useState } from "react";

//import styles
import s from "./Payment.module.css";

//import functions
import { CaretDownIcon, PenIcon } from "../../svgicons";
import {
  currencyFormat,
  dateFormat,
} from "../../../actions/formatter";
import { useSelector } from "react-redux";
import { getTaxAmmount, getTaxPercent } from "../../../actions/commonFunctions";

const OrderSummaryComponent = ({ navigateToProduct, cartData }) => {
  const { ammount, product } = cartData || {};
  const {
    preferredTimeRange,
    pickupDate,
    deliveryDate,
    zipCode,
    state,
    city,
    address,
  } = cartData?.serviceAddress || {};

  const [detail, setDetail] = useState(false);

  const taxrate = useSelector((state) => state.taxrate);
  const taxAmmount =
    cartData && taxrate?.combined_rate
      ? getTaxAmmount(taxrate?.combined_rate, cartData.ammount)
      : 0;

  const totalAmmount = currencyFormat(cartData ? taxAmmount + ammount : 0);
  const productAmmount = currencyFormat(cartData?.pricingStructure?.price);

  const toggleDetails = () => {
    setDetail((d) => !d);
  };

  const filteredAdditionalItems = cartData?.additionalFee.filter(
    (p) => p?.quantity > 0
  );

  return (
    <>
      <div className="d-flex align-items-start justify-content-between">
        <div className="form-title">Order Summary</div>
        <button onClick={navigateToProduct} className="btn btn-edit">
          <PenIcon />
        </button>
      </div>
      <div className="card">
        <div className="card-body">
          <div className={s.Subtitle}>Service Details</div>
          <div className={s.OrderTime}>
            <strong>Delivery:</strong> {dateFormat(deliveryDate)}
            {preferredTimeRange &&
              "@ " + preferredTimeRange?.replaceAll(" ", "")}{" "}
            <br />
            {pickupDate && (
              <span>
                <strong>Pickup:</strong> {dateFormat(pickupDate)} @{" "}
                {preferredTimeRange && preferredTimeRange?.replaceAll(" ", "")}
              </span>
            )}
          </div>
          <div className={`${s.Address}`} style={{ paddingRight: 50 }}>
            {address} <br />
            {city}, {state} {zipCode}
          </div>
        </div>
        <div className={s.ProductTitle}>{product?.name}</div>
        <div className="card-body">
          <ul className={s.ProductItemList}>
            <li>
              <span>
                Product delivery & pickup
                {/* @ {productAmmount}/week */}
              </span>
              <span>{productAmmount}</span>
            </li>
            {filteredAdditionalItems?.map((v, i) => (
              <li key={i}>
                <span>{v?.name} x {v?.quantity}</span>
                <span>{currencyFormat(v?.quantity * v?.charge?.value)}</span>
              </li>
            ))}
          </ul>
          <ul className={`${s.ProductItemList} ${s.BorderClass}`}>
            <li>
              <span>
                <div
                  onClick={toggleDetails}
                  className={`btn px-0 ${s.InfoBtn} d-flex align-items-center`}
                >
                  Taxes & Fees{" "}
                  <CaretDownIcon
                    style={{ transform: detail && "scaleY(-1)" }}
                    className={s.Icon}
                  />
                </div>
              </span>
              <span>{currencyFormat(taxAmmount)}</span>
            </li>
            {detail && (
              <li className="mb-1">
                <span>
                  Sales Tax (
                  {taxrate?.combined_rate
                    ? getTaxPercent(taxrate?.combined_rate)
                    : 0}
                  %)
                </span>
                <span>{currencyFormat(taxAmmount)}</span>
              </li>
            )}
          </ul>
          <div className={s.ProductSummaryFooter}>
            <span className={s.ProductSummaryLabel}>
              Total Due <br />
              Upon Delivery{" "}
            </span>
            <span className={s.ProductSummaryAmount}>{totalAmmount}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderSummaryComponent;
