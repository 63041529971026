import React, {useState} from "react";
import s from "../Home/login.module.css";
import {addZipToLocal} from "../../../actions/localstorage";
import ZipCodeCommonComponent from "../ZipCodeComponent";

const ZipCodeComponent = () => {
    const [suggestions, setSuggestions] = useState([]);
    const [data] = useState({
        zipCode: "",
        date: "",
    });

    const handleSubmit = (values) => {
        const referrer = localStorage.getItem('referrer');
        addZipToLocal(values);
        const dataObj = {
            source: 'curbwaste-ecommerce',
            url: `/product?referrer=${referrer}&zipcode=${values.zipCode}&date=${values.date}`
        };
        const measurementId = localStorage.getItem('measurementId');
        dataObj.url = measurementId ? dataObj.url+ `&measurementId=${measurementId}` : dataObj.url;
        window.parent.postMessage(dataObj, '*');
    };

    return (
        <>
            <div className={`rounded border col-sm-5 ${s.CardWrapper}`}>
                <ZipCodeCommonComponent
                    data={data}
                    suggestions={suggestions}
                    setSuggestions={setSuggestions}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    );
};
export default ZipCodeComponent;
