import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.14 2.102c.86.859.86 2.226 0 3.086L17.97 6.359 14.14 2.531l1.171-1.172a2.17 2.17 0 013.086 0l.743.743zM6.72 9.953l6.523-6.523 3.828 3.828-6.523 6.523c-.235.235-.547.43-.86.547l-3.476 1.133c-.352.117-.703.039-.938-.234a.857.857 0 01-.234-.938l1.133-3.476c.117-.313.312-.626.547-.86zM7.5 3c.664 0 1.25.586 1.25 1.25 0 .703-.586 1.25-1.25 1.25H3.75c-.703 0-1.25.586-1.25 1.25v10c0 .703.547 1.25 1.25 1.25h10c.664 0 1.25-.547 1.25-1.25V13c0-.664.547-1.25 1.25-1.25.664 0 1.25.586 1.25 1.25v3.75c0 2.07-1.68 3.75-3.75 3.75h-10C1.64 20.5 0 18.82 0 16.75v-10C0 4.68 1.64 3 3.75 3H7.5z"
    ></path>
  </svg>
);

export default SvgComponent;
