import React from "react";

function CardPayabliComponent({ engineRef }) {
    return (
        <>
            <div ref={engineRef} id="pay-component" ></div>
        </>
    );
}

export default CardPayabliComponent;
