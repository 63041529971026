import React from "react";
import s from "./login.module.css";

const TermsAndCondition = () => {
  const termsAndConditions = JSON.parse(localStorage.getItem('companyData'));
  const tnc = termsAndConditions.termsAndCondition.tnc.formattedText;

  return (
    <>
      <section className={s.SectionDefault}>
        <div className={`container p-3 ${s.Terms}`}>
          <div dangerouslySetInnerHTML={{ __html: tnc }} />
        </div>
      </section>
    </>
  );
};
export default TermsAndCondition;
