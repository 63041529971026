import React from "react";
import { ErrorMessage, Field } from "formik";
// import "./react-phone-number-input/style.css";

//import styles
import s from "./input.module.css";

//import components
import { InvalidError } from "..";
import { formatPhoneNumber } from "../../../actions/commonFunctions";

const NumberInput = ({
  label,
  placeholder,
  name,
  mainClassName,
  dark,
  data,
  setData,
}) => {
  return (
    <div className={`form-group ${mainClassName} ${s.FormGridCol}`}>
      <label className={`label-primary ${dark && "dark"}`}>{label}*</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          const onChange = (e) => {
            const onlyNumbers = e.target.value.replace(/[( )-]/g, "")
             
            if (onlyNumbers.length <= 10) {
              setFieldValue(name, onlyNumbers);
              setData && setData((data) => ({ ...data, [name]: onlyNumbers }));
            }
          };

          return (
            <input
              type="tel"
              pattern="^\(\d{3}\) \d{3}-\d{4}$|^\d{3}-\d{3}-\d{4}$"
              placeholder={placeholder}
              onChange={onChange}
              className="form-control"
              value={value && formatPhoneNumber(value)}
              defaultValue={value}
            />
          );
        }}
      </Field>
      <ErrorMessage
        children={(err) => <InvalidError text={err} />}
        name={name}
      />
    </div>
  );
};

export default NumberInput;
