import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="15"
    fill="none"
    viewBox="0 0 13 15"
    {...props}
  >
    <path
      //  fill="currentColor"
      fill="gray"
      d="M4.156 2.5h3.938V1.406c0-.355.273-.656.656-.656.355 0 .656.3.656.656V2.5H10.5c.957 0 1.75.793 1.75 1.75V13c0 .984-.793 1.75-1.75 1.75H1.75C.766 14.75 0 13.984 0 13V4.25C0 3.293.766 2.5 1.75 2.5h1.094V1.406c0-.355.273-.656.656-.656.355 0 .656.3.656.656V2.5zM1.313 7.531H3.5V6H1.312v1.531zm0 1.313v1.75H3.5v-1.75H1.312zm3.5 0v1.75h2.625v-1.75H4.813zm3.937 0v1.75h2.188v-1.75H8.75zM10.938 6H8.75v1.531h2.188V6zm0 5.906H8.75v1.531h1.75a.45.45 0 00.438-.437v-1.094zm-3.5 0H4.813v1.531h2.625v-1.53zm-3.938 0H1.312V13c0 .246.192.438.438.438H3.5v-1.532zM7.438 6H4.813v1.531h2.625V6z"
    ></path>
  </svg>
);

export default SvgComponent;
