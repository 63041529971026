import React from "react";

//import styles
import s from "./card.module.css";

const ThanksScreenCard = ({ first, second, third }) => {
  return (
    <div className={s.OrderDetailsGridItem}>
      <strong>{first}</strong> <br />
      {second}
      <br />
      {third}
    </div>
  );
};

export default ThanksScreenCard;
