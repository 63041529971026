import dayjs from "../theme/plugins/dayjs";

export const dateFormat = (date) => {
  return dayjs(date).format("ddd, MMMM D, YYYY");
};

export const shortDateFormat = (date) => {
  return dayjs(date).format("dddd, MM/DD/YYYY ");
};

const currencyFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatM_d_Y = (date) => dayjs(date).format("MM-DD-YYYY");

export const formatDateSlash = (date) => dayjs(date).format("MM/DD/YYYY");

export const currencyFormat = (ammount) => {
  return currencyFormatter.format(ammount);
};

export const timeFormat = (time) => {
  return dayjs(time).format("h:mm A");
};

export const fixPercent = (data) => Number(data).toFixed(3)
