import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="currentColor"
      d="M0 7c0 3.883 3.145 7 7 7 3.883 0 7-3.117 7-7 0-3.855-3.117-7-7-7-3.855 0-7 3.145-7 7zm6.754-3.664l3.062 3.062a.848.848 0 01.246.602.866.866 0 01-.246.629L6.754 10.69a.843.843 0 01-1.23 0 .843.843 0 010-1.23L7.956 7 5.523 4.566a.843.843 0 010-1.23.843.843 0 011.23 0z"
    ></path>
  </svg>
);

export default SvgComponent;
