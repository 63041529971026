import { updateTaxRate } from "../redux/reducers/appreducer";
import { GET_METHOD, POST_METHOD } from "./axiosMethods";

const getHeaders = (forOrder) => ({
  companyId: localStorage.getItem('referrer'),
});

//get zipcode suggestions
export const getZipSuggestions = async (search) => {
  const { data } = await GET_METHOD(`companies/service-zipcodes`, {
    params: {
      mode: "AUTOCOMPLETE",
      q: search,
    },
    headers: getHeaders(),
  });
  if (data.statusCode === 200) {
    return data.data.map((v) => ({ ...v, active: false }));
  } else {
    return [];
  }
};

//get skus by zipcode
export const getSKUsBYCode = async (zipcode) => {
  const { data } = await GET_METHOD(`companies/ecommerce/skus`, {
    params: {
      pageNo: 1,
      zipcode: Number(zipcode),
    },
    headers: getHeaders(),
  });
  if (data.statusCode === 200) {
    return data?.data;
  } else {
    return [];
  }
};

//get taxes function
//get skus by zipcode
export const getTaxRate = async (
  zipcode,
  city,
  state,
  country,
  dispatch,
  street
) => {
  const { data } = await GET_METHOD(`salestax`, {
    params: { zip: zipcode, city, state, country, street },
  });
  dispatch(updateTaxRate(data));
};

//get charges
export const getCharges = async () => {
  const { data } = await GET_METHOD(`companies/ecommerce/charges`, {
    params: {
      pageNo: 1,
    },
    headers: getHeaders(),
  });
  if (data.statusCode === 200) {
    return data?.data;
  } else {
    return [];
  }
};

export const orderPlaceApi = async (orderdata) => {
  return await POST_METHOD("orders/ecommerce", orderdata, {
    headers: getHeaders(true),
  });
};

export const getCompanyData = async () => {
  const referrer = localStorage.getItem('referrer');
  const { data } = await GET_METHOD(`companies/ecommerce/${referrer}`,{headers: getHeaders()});
  if (data.statusCode === 200) {
    return data?.data;
  } else {
    return [];
  }
};
