import React from "react";
import { ErrorMessage, Field } from "formik";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

//import styles
import s from "./input.module.css";

//import components
import { InvalidError } from "..";
import { getTaxRate } from "../../../api/api";
import { useDispatch } from "react-redux";

const MapInput = ({
  label,
  placeholder,
  onChange,
  type,
  name,
  textarea,
  mainClassName,
  dark,
  tooltipText,
  tooltipTitle,
  maxlength,
  getTax,
  data,
  setData,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={`form-group ${s.FormGridCol}`}>
      <label className={`label-primary ${dark && "dark"} `}>{label}</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const { value } = field;
          const handleChange = (address) => {
            setFieldValue(name, address);
            setData && setData((data) => ({ ...data, [name]: address }));
          };
          const handleAddress = async (address) => {
            const isSetToState = false;
            try {
              const results = await geocodeByAddress(address);
              let state = "";
              let city = "";
              let zip = "";
              let street = "";
              let route = "";
              let town = "";
              let latitute = results[0].geometry.location.lat();
              let longitude = results[0].geometry.location.lng();
              let country = "";
              let shortAddress = address.split(",")[0];
              results[0].address_components.map((addr) => {
                if (
                  addr.types.indexOf("locality") !== -1 ||
                  addr.types.indexOf("sublocality") !== -1
                ) {
                  city = isSetToState ? `, ${addr.long_name}` : addr.long_name;
                }
                if (addr.types.indexOf("administrative_area_level_1") !== -1) {
                  state = isSetToState
                    ? `, ${addr.short_name}`
                    : addr.short_name;
                }
                if (addr.types.indexOf("country") !== -1) {
                  country = addr.short_name;
                }
                if (addr.types.indexOf("postal_code") !== -1) {
                  zip = addr.long_name;
                }
                if (addr.types.indexOf("street_number") !== -1) {
                  street = addr.long_name;
                }
                if (addr.types.indexOf("route") !== -1) {
                  route = addr.long_name;
                }
                if (addr.types.indexOf("sublocality") !== -1) {
                  town = addr.long_name;
                }
                return addr;
              });

              setFieldValue(name, shortAddress);
              setFieldValue("zipCode", zip);
              setFieldValue("city", city);
              setFieldValue("state", state);
              setFieldValue("formatted_address", results[0]?.formatted_address);
              setFieldValue("place_id", results[0]?.place_id);
              setFieldValue("town", town);
              setFieldValue("street", street);
              setFieldValue("route", route);
              setFieldValue("longitude", longitude);
              setFieldValue("latitute", latitute);

              getTax &&
                (await getTaxRate(
                  zip,
                  city,
                  state.length > 2 ? country : state,
                  country,
                  dispatch,
                  street
                ));
              setData &&
                setData((data) => ({
                  ...data,
                  zipCode: zip,
                  city,
                  state,
                  [name]: shortAddress,
                }));
            } catch (err) {
              console.log(err);
            }
          };

          const handleSelect = (address) => {
            handleAddress(address);
          };
          return (
            <PlacesAutocomplete
              value={!data ? value : data[name] && data[name]}
              onChange={handleChange}
              onSelect={handleSelect}
              searchOptions={{ componentRestrictions: { country: 'US' }}}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      className: "form-control",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    <div className={suggestions.length && "border shadow p-1"}>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active py-2 px-1"
                          : "suggestion-item py-2 px-1";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          );
        }}
      </Field>

      <ErrorMessage
        children={(err) => <InvalidError text={err} />}
        name={name}
      />
    </div>
  );
};

export default MapInput;
