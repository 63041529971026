import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7 0C3.117 0 0 3.145 0 7c0 3.883 3.117 7 7 7 3.855 0 7-3.117 7-7 0-3.855-3.145-7-7-7zm3.664 6.754L7.602 9.816a.848.848 0 01-.602.246.866.866 0 01-.629-.246L3.31 6.754a.843.843 0 010-1.23.843.843 0 011.23 0L7 7.956l2.434-2.434a.843.843 0 011.23 0 .843.843 0 010 1.23z"
    ></path>
  </svg>
);

export default SvgComponent;
