import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//import components
import { AdditionalCard } from "../../common";
import { CaretRightIcon } from "../../svgicons";

//import functions
import {
  addProductToCart,
  breadCrumbChange,
} from "../../../redux/reducers/appreducer";
import {
  addToCartLocalStorage,
  getCartLocalStorage,
  getCurrentproduct,
} from "../../../actions/localstorage";
import ReactGA from "react-ga4";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cartData = getCartLocalStorage();
  const currentProduct = getCurrentproduct();

  const companyData = JSON.parse(sessionStorage.getItem('companyData'));

  const additionFee = cartData
    ? cartData?.additionalFee
    : currentProduct?.additionalFee;

  const [additional, setAdditional] = useState(additionFee);
  const [ammount] = useState(Number(currentProduct?.pricingStructure?.price));

  const addToCart = (withtouadd) => {
    const fixedCharge = additional?.filter((chg) => chg?.charge?.type === 'FIXED').reduce((ent, currentValue) => {
      const charge = currentValue?.charge?.value
          ? Number(currentValue?.charge?.value)
          : 0;
      return (
          ent +
          charge * (currentValue.quantity ? Number(currentValue.quantity) : 0)
      );
    }, 0);

    const perCharge = additional?.filter((chg) => chg?.charge?.type === 'PERCENTAGE').reduce((ent, currentValue) => {
      const chargeAmount = (currentValue.charge.value / 100) * ammount * currentValue.quantity;
      return ent + chargeAmount;
    }, 0);

    const sum = fixedCharge + perCharge;
    const withoutAddAdditional = additional.map((v) => ({ ...v, quantity: 0 }));
    const data = {
      ...currentProduct,
      additionalFee: withtouadd ? withoutAddAdditional : additional,
      ammount: withtouadd ? ammount : ammount + sum,
      tax: 0,
      serviceAddress: {},
      billingInformation: {},
      taxRate: 0,
    };
    addToCartLocalStorage(data);
    dispatch(addProductToCart(data));
    navigate("/service-details");
    ReactGA.event({
      category: 'Ecommerce',
      action: 'Add to Cart',
      label: data?.product?.name ? data.product.name : undefined,
      value: data?.ammount ? data.ammount : 0,
    });
  };

  useEffect(() => {
    dispatch(breadCrumbChange(1));
  }, []);

  const onChange = (e, i) => {
    const { value } = e.target;

    value >= 0 &&
      setAdditional((prev) => {
        const data = [...prev];
        const thisInd = { ...prev[i], quantity: Number(value) };
        data.splice(i, 1, thisInd);
        return data;
      });
  };

  return (
    <>
      <div className="container">
        <div className="form-header mt-3">
          <div className="form-header-col">
            <div className="form-header-col-inner">
              <h1 className="form-header-title">Additional Items</h1>
              <p className="form-header-subtitle">
                Additional fees will apply for the following items.
              </p>
            </div>
          </div>
          <div className="form-header-col">
            <button
              onClick={() => addToCart(true)}
              className="btn btn-edit-without-bg"
            >
              Continue Without Adding Additional Items{" "}
              <CaretRightIcon className="icon-right" />
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8">
            <div className="table-container m-0 p-0">
              <table className="table table-custom">
                <thead>
                  <th>Item</th>
                  <th className="table-cell-last">Price Per</th>
                  <th className="table-cell-last">Qty</th>
                </thead>
                <tbody>
                  {additional?.map((v, i) => (
                    <AdditionalCard
                      index={i}
                      onChange={onChange}
                      key={i}
                      v={v}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-footer addtocart-container">
              <button
                onClick={() => addToCart(false)}
                type="button"
                className="btn btn-primary"
              >
                Add to Cart
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <div className="prohibited-card">
              <div className="prohibited-card-header">
                <h2 className="prohibited-title">Prohibited Items</h2>
                <p className="prohibited-subtitle">
                  The following items are not allowed in any dumpster
                </p>
              </div>

              <ul className="prohibited-item-list">
                {
                  companyData && companyData?.prohibitedItems && companyData?.prohibitedItems?.length > 0 ?
                    companyData?.prohibitedItems.map((v, i) => (
                      <li key={i}>{v}</li>
                    )) : null
                }
                {/* {addtionalData?.prohibitedItems.map((v, i) => (
                  <li key={i}>{v}</li>
                ))} */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
