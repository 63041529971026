import * as React from "react";

const SvgComponent = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6 0C2.672 0 0 2.695 0 6c0 3.328 2.672 6 6 6 3.305 0 6-2.672 6-6 0-3.305-2.695-6-6-6zm0 3c.398 0 .75.352.75.75 0 .422-.352.75-.75.75a.74.74 0 01-.75-.75c0-.398.328-.75.75-.75zm.938 6H5.063a.542.542 0 01-.563-.563c0-.304.234-.562.563-.562h.375v-1.5H5.25a.542.542 0 01-.563-.563c0-.304.235-.562.563-.562H6a.57.57 0 01.563.563v2.062h.375a.57.57 0 01.562.563.555.555 0 01-.563.562z"
    ></path>
  </svg>
);

export default SvgComponent;
