import React, {useState} from "react";
import s from "../Home/login.module.css";
import {Form, Formik} from "formik";
import {zipCodeValidation} from "../../../actions/validations";
import {CustomDateInput, ZipCodeInput} from "../../common";

const ZipCodeCommonComponent = ({data, suggestions, setSuggestions, handleSubmit}) => {
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    return (
        <>
            <div className={s.HeadWrapperInner}>
                <div className={s.HeadWrapperInnerWrap}>
                    <Formik
                        initialValues={data}
                        validate={(v) => zipCodeValidation(v, suggestions)}
                        onSubmit={handleSubmit}
                    >
                        {({errors, touched}) => (
                            <Form className={s.HeadWrapperFormGrid}>
                                <div className={s.HomeScreenInputContainer}>
                                    <ZipCodeInput
                                        setSuggestions={setSuggestions}
                                        suggestions={suggestions}
                                        label={"Service Location"}
                                        placeholder={"Zip Code..."}
                                        type={"number"}
                                        name={"zipCode"}
                                        setIsBtnDisabled={setIsBtnDisabled}
                                        dark
                                    />
                                </div>
                                <div className={s.HomeScreenInputContainer}>
                                    <CustomDateInput
                                        label={"Select Service Date"}
                                        placeholder={"Service Date"}
                                        name={"date"}
                                        dark
                                        required
                                    />
                                </div>
                                <div className={s.SubmitContainer}>
                                    <button
                                        type="submit"
                                        className={`btn ${s.SubmitButton}`}
                                        disabled={isBtnDisabled}
                                    >
                                        Get Rates
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default ZipCodeCommonComponent;
